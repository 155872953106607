import React, { forwardRef } from "react";
import memoize from "utils/memoize";
import LoadingSpinner from "@RHCommerceDev/printable-pages/components/LoadingSpinner";

type PrintFrameProps = {
  src: string;
  injectIframe: boolean;
  id?: string;
  title?: string;
  visible?: boolean;
  enabled?: boolean;
  showLoader?: boolean;
  onLoad?: (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => void;
};

const PrintFrame = forwardRef<HTMLIFrameElement, PrintFrameProps>(
  (
    {
      src,
      id = "print-document",
      title = "Print Document",
      visible,
      onLoad,
      showLoader,
      injectIframe
    },
    ref
  ) => {
    const handleIframeLoad = event => {
      const iframe =
        typeof ref === "function" ? ref(event.target) : ref?.current;
      if (!iframe) return;

      const iframeDoc =
        iframe.contentDocument || iframe?.contentWindow?.document;

      const metaTagsToRemove = [
        "keywords",
        "cqTags",
        "subtitle",
        "navTitle",
        "rhBrand",
        "pageTitle",
        "description",
        "pageDescription"
      ];
      metaTagsToRemove.forEach(tagName => {
        const metaTags = iframeDoc?.querySelectorAll(`meta[name="${tagName}"]`);
        metaTags?.forEach(tag => tag?.parentNode?.removeChild(tag));
      });

      const metaPropertiesToRemove = [
        "og:site_name",
        "og:fb_admins",
        "og:title",
        "og:type",
        "og:description",
        "og:image",
        "og:url"
      ];
      metaPropertiesToRemove.forEach(property => {
        const metaTags = iframeDoc?.querySelectorAll(
          `meta[property="${property}"]`
        );
        metaTags?.forEach(tag => tag?.parentNode?.removeChild(tag));
      });

      if (onLoad) onLoad(event);
    };
    return (
      <>
        {showLoader && <LoadingSpinner />}
        {injectIframe && (
          <iframe
            ref={ref}
            className="hidden"
            id={id}
            title={title}
            src={src}
            onLoad={handleIframeLoad}
            style={{
              display: visible ? "unset" : "none",
              width: "100%",
              height: 200
            }}
          />
        )}
      </>
    );
  }
);

export default memoize(PrintFrame);
