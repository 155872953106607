import React from "react";
import RHSpinner from "component-rh-spinner";
import memoize from "utils/memoize";

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center fixed inset-0 bg-white/90 z-[1110]">
      <RHSpinner />
    </div>
  );
};

export default memoize(LoadingSpinner);
